import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { firstValueFrom, combineLatest } from 'rxjs';
import { map, skipWhile, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserFS } from 'src/app/core/models/user/user';
import { NO_TEFTER_ACCOUNT, NO_USER, UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const currentRoute = state.url;

    try {
      const authData = await firstValueFrom(
        combineLatest([this.userService.userAsObservable(), this.authService.idToken$]).pipe(
          //tap(([userFS, token]) => console.log('🛡️ AuthGuard: Checking authentication...', userFS, token)),
          skipWhile(([userFS, token]) => {
            //console.log("⏳ AuthGuard: Waiting for token...", token, userFS);
            return !token || token === "TOKEN_PENDING";
          }),
          map(([userFS, token]) => {
            if (!token) {
              console.warn("🚫 AuthGuard: No valid token. Redirecting to /auth.");
              return this.navigateToSpecificPage(currentRoute, '/auth');
            }

            if (!userFS || !userFS.id || userFS.id === NO_USER.id || userFS.registrationStatus === NO_TEFTER_ACCOUNT) {
              console.warn("🚫 AuthGuard: No valid Firestore user. Redirecting to register-user.");
              return this.navigateToSpecificPage(currentRoute, '/account/register-user');
            }

            if (!userFS.orgId) {
              console.warn("🚫 AuthGuard: No organization. Redirecting to register-organization.");
              return this.navigateToSpecificPage(currentRoute, '/account/register-organization');
            }

            if (!token.claims.orgId && !token.claims.orgIds) {
              console.warn("🚫 AuthGuard: No organization claims. Redirecting to /auth.");
              return this.navigateToSpecificPage(currentRoute, '/auth');
            }

            console.log("✅ AuthGuard: User authenticated. Proceeding...");
            return true;
          }),
          //take(1)
        )
      );

      //console.log('✅ AuthGuard: Passed authentication check', authData);
      return authData;
    } catch (error) {
      console.error("🚨 AuthGuard: Error occurred", error);
      return this.router.parseUrl('/auth');
    }
  }

  private navigateToSpecificPage(currentRoute: string, urlPath: string): UrlTree {
    console.log(`🔄 AuthGuard: Redirecting from ${currentRoute} to ${urlPath}`);
    return this.router.parseUrl(urlPath);
  }
}
