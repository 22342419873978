import { Component, AfterViewInit } from '@angular/core';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { CountryCodeService } from 'src/app/core/services/country-code.service';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidators as Validators } from 'src/app/core/validators/custom.validators';
import { RecaptchaVerifier, signInWithCustomToken, Auth, signInWithPhoneNumber } from '@angular/fire/auth';
import { AuthService } from '../../services/auth.service';
import { InfoDialogComponent } from 'src/app/core/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { UserFS } from 'src/app/core/models/user/user';

@Component({
  selector: 'app-phone-login-and-verification',
  templateUrl: './phone-login-and-verification.component.html',
  styleUrls: ['./phone-login-and-verification.component.sass'],
  standalone: false
})
export class PhoneLoginAndVerificationComponent implements AfterViewInit {

  showProgress: boolean = false;
  phoneSubmitted = false;

  phoneNumber = this.fb.control('', [Validators.required, Validators.minLength(6)]);
  verificationCode = this.fb.control({ value: '', disabled: true }, [Validators.required]);

  loginForm = this.fb.group({
    phoneNumber: this.phoneNumber,
    verificationCode: this.verificationCode
  });

  selectedCountryCode: CountryCode;
  recaptchaVerifier!: RecaptchaVerifier;
  private customToken: string = "";

  constructor(
    public dialog: MatDialog,
    private countryCodeService: CountryCodeService,
    private authService: AuthService,
    private fb: FormBuilder,
    private auth: Auth,
    private router: Router,
    private userService: UserService
  ) {
    this.selectedCountryCode = this.countryCodeService.getCodeFromLocalStorage().countryCode as CountryCode;
  }

  ngAfterViewInit() {
    // ✅ Fix: Initialize reCAPTCHA properly
    this.recaptchaVerifier = new RecaptchaVerifier(this.auth, 'recaptcha-container', {
      size: 'invisible',
      callback: (response: any) => {
        console.log("reCAPTCHA solved, proceeding with phone login:", response);
        this.showProgress = false;
      }
    });
  }

  countryCodeSelected(code: CountryCode): void {
    this.selectedCountryCode = code;
  }

  disableSubmitButton(): boolean {
    return false;
  }

  onSubmit() {
    if (!this.phoneSubmitted) {
      this.preparePhoneNumberAndSendCode();
    } else {
      this.verifyAuthCode();
    }
  }

  async verifyAuthCode() {
    if (this.loginForm.valid) {
      this.loginForm.disable();
      this.showProgress = true;
      const phoneInput = this.loginForm.get('phoneNumber')!.value ?? "";
      const phone = parsePhoneNumberFromString(phoneInput, this.selectedCountryCode);
      const verificationCode = this.loginForm.get('verificationCode')!.value ?? "0";

      try {
        console.log("Attempting to verify login with code:", verificationCode);
        const response = await firstValueFrom(this.authService.verifyOtp(phone!.number.toString(), verificationCode));
        console.log("Custom Token:", response.customToken);
        this.customToken = response.customToken;
        this.loginWithCustomToken(this.customToken);
      } catch (error: any) {
        this.dialog.open(InfoDialogComponent, {
          width: '360px',
          data: { text: error.message }
        });
        this.loginForm.enable();
        this.showProgress = false;
      }
    }
  }

  loginWithCustomToken(customToken: string) {
    signInWithCustomToken(this.auth, customToken)
      .then((userCredential) => {
        console.log(userCredential)
        console.log("User logged in:", userCredential.user.uid);
  
        // ✅ Redirect after successful login
        console.log(userCredential.user)
        let userFS = new UserFS(userCredential.user)
        this.userService.setCurrentUser(userFS); // Ensure the user state is updated
        this.router.navigate(['/app/home']); // Redirect to home or dashboard
      })
      .catch((error) => {
        console.error("Error logging in with custom token:", error);
      });
  }

  async preparePhoneNumberAndSendCode() {
    if (this.loginForm.get('phoneNumber')?.valid) {
      this.showProgress = true;
      this.loginForm.get('phoneNumber')?.disable();
      this.loginForm.get('verificationCode')?.enable();
      const phoneInput = this.loginForm.get('phoneNumber')!.value ?? "";
      const phone = parsePhoneNumberFromString(phoneInput, this.selectedCountryCode);

      if (!phone) {
        console.log('Phone number is required');
        return;
      }

      try {
        const recaptchaToken = await this.recaptchaVerifier.verify();
        console.log("Recaptcha verified, sending OTP...");

        const result = await firstValueFrom(this.authService.sendOtpCode(phone.number.toString(), recaptchaToken));
        console.log("OTP sent successfully:", result);
        this.phoneSubmitted = true;
        this.loginForm.get('verificationCode')?.enable();
      } catch (error: any) {
        this.dialog.open(InfoDialogComponent, {
          width: '360px',
          data: { text: `Error: ${error.message}` }
        });
        this.loginForm.get('phoneNumber')?.enable();
        this.loginForm.get('verificationCode')?.disable();
        this.showProgress = false;
      }
    }
  }

  resendCode() {
    console.log("Resend code function not implemented yet.");
  }
}
